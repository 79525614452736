import React, { useEffect, useState } from 'react'
import App from './App'
import { useAuth } from "./api/authStore"
import { loginUrl } from './config/config'
import ApiClient, {getCfgByOrigin} from "./api/api"

const AppWrapper = (props) => {
    let params = new URLSearchParams(document.location.search)
    let token = params.get("token")

    // console.log('Token'+token)

    let path = window.location.pathname.replace(/\//g, "")
    
    const apiClient = new ApiClient(getCfgByOrigin())

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()
    const [userData, setUserData] = useState(null)
    const [activeProxies, seActiveProxies] = useState([])
    const [reloadActive, setReloadActive] = React.useState(false)
    const [reloadData, setReloadData] = React.useState(false)

    // console.log('USER')
    // console.log(user)
    useEffect(()=>{
        if (!user) {
          if (path !== 'login' && path !== 'signup' && path !== 'passwdrecovery'){
            // console.log(path)
            logout(loginUrl)
          } 
        } else {
            const callAsync = async() => {
              try {
                  const userDataResponse = await apiClient.setToken(getAccessTokenSilently()).getUserData()
                  setUserData(userDataResponse)
                  // console.log(userDataResponse)
                  const activeResponse = await apiClient.setToken(getAccessTokenSilently()).loacActiveProxies()
                  seActiveProxies(activeResponse)

                  setReloadActive(false)
                  setReloadData(false)
                  // console.log(activeResponse)
              } catch (e) {

              }
            }

            callAsync()  
          }  
    },[reloadActive, reloadData])
    
    return (
        <>
          <App userData={userData} active={activeProxies} apiClient={apiClient} setReloadActive={setReloadActive} setReloadData={setReloadData}/>
        </>
    )
}

export default AppWrapper