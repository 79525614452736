import * as React from 'react'
import { makeStyles } from '@mui/styles'
import LeftSideMenu from './LeftSideMenu'
import PaymentsTable from './PaymentsTable'

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        display: 'flex',
        height: '100%',
    },
}))

const Payments = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.mainDiv}>
            <LeftSideMenu active={props.active} user={props.user} apiClient={props.apiClient}/>
            <PaymentsTable apiClient={props.apiClient}/>            
        </div>
    )
}

export default Payments