import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Drawer from '@mui/material/Drawer'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ArticleIcon from '@mui/icons-material/Article'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import ListAltIcon from '@mui/icons-material/ListAlt'
import FlutterDashIcon from '@mui/icons-material/FlutterDash'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt'
import { useAuth } from "./api/authStore"
import { loginUrl } from './config/config'
import { deepOrange } from '@mui/material/colors'
import ListItemText from '@mui/material/ListItemText'
import PaymentDialog from './PaymentDialog'
import logo from './img/logo.svg'

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '22%',
        height: '100vh',
        // borderRight: 'solid',
        // borderColor: '#B6BABF',
        
    },
    firtLayerMenu: {
        flexDirection: 'row',
        marginTop: '12px',
    }, 
   secondLayerMenu: {
        flexDirection: 'row',
        marginTop: '20px',
        height: '144px',
    }, 
    thirdLayerMenu: {
        flexDirection: 'row',
        marginTop: '25px'
    }, 
    fourthLayerMenu: {
        flexDirection: 'row',
        marginTop: '50px',
        position: 'relative',
    },
    brandDiv: {
        width: '304px',
        height: '40px',
        fontSize: 24,
        // paddingTop: '28px',
        marginLeft: '68px',
        // paddingBottom: '20px',
        color: '#ffffff',
    },
    accountDiv: {
        width: '304px',
        // height: '96px',
        borderRadius: 12,
        marginLeft: '16px',
        marginRight: '16px',
        display: 'grid',
        flexDirection: 'row',
        color: '#A64DFF',
    },
    accountDiv2: {
        width: '304px',
        // height: '96px',
        borderRadius: 12,
        marginLeft: '16px',
        marginRight: '16px',
        display: 'grid',
        flexDirection: 'row',
        color: '#A64DFF',
    },
    secondLayerMenuDiv: {
        width: '304px',
        height: '96px',
        borderRadius: 12,
        marginLeft: '16px',
        marginRight: '16px',
        display: 'grid',
        flexDirection: 'row',
        color: '#A64DFF',
        fontSize: 14,
    },
    columnDiv: {
        display: 'inline-block',
        height: '32px',
    },
    gridColumnDiv: {
        display: 'flex',
        height: '32px',
    },
    img: {
        width: '24px',
        height: '24px',
        fill: '#A64DFF',
    },
    imgDiv: {
        float: 'left',
        marginLeft: '18px',
        marginTop: '16px',
        marginBottom: '16px',
    },
    emailDiv: {
        float: 'left',
        color:'#A64DFF',
        fontFamily: 'system-ui',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '32px',
        height: '32px',
        width: '192px',
        marginTop: '12px',
        // marginBottom: '6px',
        marginLeft: '6px'
    },
    balanceDiv: {
        float: 'left',
        color:'#A64DFF',
        fontFamily: 'system-ui',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '32px',
        height: '32px',
        width: '206px',
        marginTop: '12px',
        // marginBottom: '6px',
        marginLeft: '6px',
    },
    balanceSpan: {
        // color:'#ffffff',
        paddingLeft: '10px',
        height: '32px',
        width: '216px',
    },
    addFunds: {
       color: '#00B24A',
    //    paddingLeft: '10px',
    },
    selectedItem: {
        backgroundColor: '#ffffff',
        width: '260px',
        borderRadius: 8,
        paddingLeft: '10px',
    },
    secondLayerMenuItem: {
        float: 'left',
        color:'#A64DFF',
        fontFamily: 'system-ui',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '32px',
        height: '32px',
        marginTop: '12px',
        marginBottom: '6px',
        marginLeft: '6px',
    },
    activeProxiesCount: {
        // paddingLeft: '140px'
    },
    linsStyle: {
        color: '#8000FF',
        textDecoration: 'none',
    },
}))

const LeftSideMenu = (props) => {
    const classes = useStyles()
    
    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const [openPaymentDialog, setOpentPaymentDialog] = React.useState(false)
    const accountEmail = props.user?.email || ''

    const regex = /(.*)\@/g;
    const found = accountEmail.match(regex)
    
    const avatar = (found !== null )?found[0].charAt(0)+found[0].charAt(found[0].length-2):'UD'

    const balance = (props.user?.balanceCents/100).toFixed(2) || '0.00'
    const activeProxiesList = props.active?.leases || []

    const activeProxiesCount = activeProxiesList.length

    // console.log(props.user)

    const handleLogout = () => {
        logout(loginUrl)
    }

    const handleOpenPaymentDialog = () => {
        setOpentPaymentDialog(true)
    }

    return (
        <div className={classes.mainDiv}>
           
           <Drawer open={true}
                   variant="persistent"
                   PaperProps={{
                    sx: {
                        width: '20%'
                      }
                  }}     
            >
            <DialogTitle><img src={logo}/>{process.env.REACT_APP_NAME}</DialogTitle>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    p: 1.5,
                    pb: 2,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Avatar size="md" sx={{ bgcolor: 'rgba(25, 118, 210, 0.08)' }}>{avatar.toUpperCase()}</Avatar>
                <div>
                    <Typography level="title-md"><Link href='/account' underline="none" sx={{color:'#6E7680'}}>{accountEmail.replace(/(.{30})..+/, "$1…")}</Link></Typography>
                    <Typography level="body-sm">Balance<span className={classes.balanceSpan}>${balance}</span> <span className={classes.addFunds} onClick={handleOpenPaymentDialog} style={{cursor:'pointer'}}>Add Funds</span></Typography>
                </div>
            </Box>
            <DialogContent sx={{padding: '0 0'}}>
            <List>
                <ListItem>
                    <Link href='/proxies' underline="none" sx={{color:'#6E7680'}}>
                        <ListItemButton sx={{paddingLeft: 0, paddingRight: 0, width: '305px'}}>
                            <ListItemIcon sx={{minWidth:'25px'}}>
                                <LocalGasStationIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Proxies Store" />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href='/active' underline="none" sx={{color:'#6E7680'}}>
                        <ListItemButton sx={{paddingLeft: 0, paddingRight: 0, width: '305px'}}>
                            <ListItemIcon sx={{minWidth:'25px'}}>
                                <FlutterDashIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Active Proxies" />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href='/transactions' underline="none" sx={{color:'#6E7680'}}>
                        <ListItemButton sx={{paddingLeft: 0, paddingRight: 0, width: '305px'}}>
                            <ListItemIcon sx={{minWidth:'25px'}}>
                                <LocalAtmIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Transactions" />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href='/history' underline="none" sx={{color:'#6E7680'}}>
                        <ListItemButton sx={{paddingLeft: 0, paddingRight: 0, width: '305px'}}>
                            <ListItemIcon sx={{minWidth:'25px'}}>
                                <AccessTimeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="History" />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href='/tickets' underline="none" sx={{color:'#6E7680'}}>
                        <ListItemButton sx={{paddingLeft: 0, paddingRight: 0, width: '305px'}}>
                            <ListItemIcon sx={{minWidth:'25px'}}>
                                <ListAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Tickets" />
                        </ListItemButton>
                    </Link>
                </ListItem>
               
            </List>
            </DialogContent>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    p: 1.5,
                    pb: 2,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
                >
                <List>
                    <ListItem>
                        <Link href='#' underline="none" sx={{color:'#6E7680'}}>
                            <ListItemButton sx={{paddingLeft: 0, paddingRight: 0, width: '300px'}}>
                            <ListItemIcon sx={{minWidth:'30px'}}>
                                <PsychologyAltIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Help" />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link href='#' underline="none" sx={{color:'#6E7680'}} onClick={handleLogout}>
                            <ListItemButton sx={{paddingLeft: 0, paddingRight: 0, width: '300px'}}>
                                <ListItemIcon sx={{minWidth:'30px'}}>
                                <PowerSettingsNewIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                </List>
            </Box>
            </Drawer>
            <PaymentDialog open={openPaymentDialog} setOpentPaymentDialog={setOpentPaymentDialog} apiClient={props.apiClient}/>
        </div> 
    )
}

export default LeftSideMenu