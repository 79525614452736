import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import LeftSideMenu from './LeftSideMenu'
import Proxies from './Proxies'

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        display: 'flex',
        height: '100%',
    }
}))

const Shop = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.mainDiv}>
            <LeftSideMenu user={props.user} active={props.active} apiClient={props.apiClient}/>
            <Proxies apiClient={props.apiClient} setReloadData={props.setReloadData}/>
        </div>
    )
}

export default Shop