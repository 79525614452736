import React, { useState, useEffect } from 'react'
import { makeStyles,  } from '@mui/styles'
import LeftSideMenu from './LeftSideMenu'
import ActiveProxiesTable  from './ActiveProxiesTable'


const useStyles = makeStyles((theme) => ({
    mainDiv: {
        display: 'flex',
        height: '100%',
    },
}))

const ActiveProxies = (props) => {
    const classes = useStyles()
    const activeProxiesList = props.active || []

    return (
        <div className={classes.mainDiv}>
            <LeftSideMenu user={props.user} active={props.active} apiClient={props.apiClient}/>
            <ActiveProxiesTable list={activeProxiesList} apiClient={props.apiClient} setReloadActive={props.setReloadActive}/>
        </div>
    )
}

export default ActiveProxies