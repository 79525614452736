import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { useAuth } from "../api/authStore"
import moment from 'moment'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/Info'
import TicketDetailsDialog from './TicketsDetailsDialog'

function TablePaginationActions(props) {
    const theme = useTheme()
    
    const { count, page, rowsPerPage, onPageChange } = props
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0)
    }
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1)
    }
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1)
    }
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
         <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
            <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        </IconButton>
      </Box>
    )
  }

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }

const useStyles = makeStyles((theme) => ({

}))



const TicketsAdmin = (props) => {
    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const classes = useStyles()

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(100000000000)
    const [ticketsList, setTicketsList] = React.useState([])
    const [selectedTicket, setSelectedTicket] = React.useState(0)
    const [ticketStatus, setStatus] = React.useState(null)


    const [open, setOpen] = React.useState(false)

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ticketsList?.length) : 0

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    useEffect(()=>{
        const callAsync = async() => {
            const ticketsPesponse = await props.apiClient.setToken(getAccessTokenSilently()).geticketsAdmin(page, rowsPerPage, ticketStatus)
            console.log(ticketsPesponse)
            setTicketsList(ticketsPesponse)
        }
        callAsync()   
      },[page, rowsPerPage, ticketStatus])
  
    const rowClick = (row) => {
        // console.log(row.id)
        setSelectedTicket(row)
        setOpen(true)
    }

    const handleStatusChange = (status) => {
        setStatus(status)
    }

    const handleCloseTicket = (status) => {
        setStatus(status)
    }

    return (
        <div>
            <Stack direction="row" spacing={1} sx={{paddingBottom:'20px'}}>
                <Chip label="Pending" color="primary" onClick={()=>{handleStatusChange('pending')}}/>
                <Chip label="Refunded" color="success" onClick={()=>{handleStatusChange('refund')}}/>
                <Chip label="Rejected" color="warning" onClick={()=>{handleStatusChange('reject')}}/>
            </Stack>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                        <Table stickyHeader sx={{borderColor: '#B6BABF'}}>
                            <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Creation Date</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Status</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Proxy ID</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Refund Reason</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Description</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {ticketsList?.map((ticket)=>(
                                    <TableRow hover
                                              onClick={() => rowClick(ticket)}
                                              selected={selectedTicket?.ticket === ticket.id}>
                                         <TableCell align="left" sx={{ borderBottom: "none"}}>{moment(ticket?.created).format('MMM-D H:m, YYYY')}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none" }}>
                                        {ticket?.status === 'pending'?(
                                            <Chip label="pending" color="primary" variant="outlined" sx={{width: '100px'}}/>
                                            ):ticket?.status === 'reject'?(
                                            <Chip label="reject" color="warning" variant="outlined" sx={{width: '100px'}}/>
                                            ):(
                                            <Chip label="refund" color="success" variant="outlined" sx={{width: '100px'}}/>
                                            )
                                        }
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none" }}>{ticket?.lease?.id}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none" }}>{ticket?.reason}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none" }}>
                                                {ticket.description}
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none" }}>
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(e)=>{rowClick(ticket, e)}}
                                                >
                                                    <InfoIcon color="primary"/>
                                                </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <div>
                        <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                        colSpan={3}
                                        count={ticketsList?.total||0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        slotProps={{
                                            select: {
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                            },
                                        }}
                                        ActionsComponent={TablePaginationActions}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                    </div> */}
            </Paper>
            <TicketDetailsDialog apiClient={props.apiClient} ticket={selectedTicket} open={open} setOpen={setOpen}/>
        </div>
    )
}

export default TicketsAdmin