import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useAuth } from "../api/authStore"

const useStyles = makeStyles((theme) => ({
    mainDiv:{
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
    },
    divRow: {
        display: 'flex',
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
     },
     columnDiv: {
        flex: '50%',
     }
}))

const UserInfo = (props) => {
    const classes = useStyles()

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const [newUserPassword, setUserPassword] = useState('')
    const [balanceC, setBalance] = useState(0)
    const [userEmail, setUserEmail] = useState('')
    const [error, setError] = useState(null)
    const [userData, setUserData] = useState([])

    

    const handleChangePassword = (passwd) => {
        setUserPassword(passwd)
    }

    const handleChangeBalance = (balance) => {
        setBalance(balance)
    }

    const handleChangeEmail = (email) => {
        setUserEmail(email)
    }


    const handleClickGetUserInfo = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).getUserInfo(userEmail)
            // console.log(response)

            setUserData(response)
        } catch (e) {
            if(e.response.data.error === 'accountNotFound'){
                setError('USER NOT FOUND')
            }
            console.log(e) 
        }
    }

    const handleClickChangePassword = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).changeUserPasswordAdmin(userData?.userID, newUserPassword)

            console.log(response)
        } catch (e) {
            console.log(e)
            setError(JSON.stringify(e))
        }
    }

    const handleAddBalance = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).addUserBalance(userData?.userID, balanceC)

            // console.log(response)
        } catch (e) {
            console.log(e)
            setError(JSON.stringify(e))
        }
    }

    return (
        <>
        <Box
            component="form"
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
            >
            <TextField  id="outlined-basic" 
                        label="user email/id" 
                        variant="outlined" 
                        inputProps={{sx:{width: '140px'}}}
                        onChange={(event)=>{handleChangeEmail(event.target.value)}}
                        value={userEmail}
                        />
            <Button variant="contained" sx={{height: '55px'}} onClick={handleClickGetUserInfo}>get info</Button>
            {error !== null &&
                <div style={{color:'red'}}>{error}</div>
            }
        </Box>
             <TableContainer component={Paper} sx={{maxWidth:'650px'}}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">User ID</TableCell>
                            <TableCell align="left" sx={{width:'320px'}}>{userData?.userID}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">User Email</TableCell>
                            <TableCell align="left">{userData?.email}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Date Registered</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Balance</TableCell>
                            <TableCell align="left">${userData?.balanceCents/100}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Deposited</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Leased</TableCell>
                            <TableCell align="left">{userData?.leased}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Refunded</TableCell>
                            <TableCell align="left">{userData?.refunded}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                        size='sm'
                                        sx={{padding: '0px 0px', paddingTop:'0'}} 
                                        inputProps={{sx:{padding:'8px 8px', width: '160px'}}} 
                                        label="password"
                                        value={props.newpass} onChange={(event)=>{handleChangePassword(event.target.value)}} />
                            </TableCell>
                            <TableCell style={{width: 200}} align="left">
                                <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleClickChangePassword}
                                        >
                                            Change Password
                                    </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                        size='sm'
                                        sx={{padding: '0px 0px', paddingTop:'0'}} 
                                        inputProps={{sx:{padding:'8px 8px', width: '160px'}}} 
                                        label="amount"
                                        value={props.newpass} onChange={(event)=>{handleChangeBalance(event.target.value)}} />
                            </TableCell>
                            <TableCell style={{width: 140}} align="left">
                                <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleAddBalance}
                                        >
                                            Add balance
                                    </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default UserInfo