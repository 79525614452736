import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import EmptyTable from './EmptyTable'
import ReactCountryFlag from "react-country-flag"
import { useAuth } from "./api/authStore"
import { countryNames } from './config/countryNames'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import ProxyDetailsDialog from './ProxyDetailsDialog'


const useStyles = makeStyles((theme) => ({
    main: {
        width: '80%',
        height: '100vh',
    },
    header: {
        // height: '72px',
        margin: '32px',
        fontWeight: 600,
    },
    content: {
        height: '72px',
        marginLeft: '18px',
        // marginRight: '32px',
    },
    tableDiv: {
        marginTop: '16px',
    },
    tableDataCell: {
        height: '20px',
        paddingTop: '15px',
    },
}))

const ActiveProxiesTable = (props) => {
    const classes = useStyles()

    const [selectedRow, setSelectedRow] = useState([])
    const [isRefund, setIsRefund] = useState(false)
    const activeProxiesList = props.list?.leases || []
    const [openDetails, setOpenDetails] = React.useState(false)

    // console.log(activeProxiesList)

    const formatTimeLeft = (date) => {
        const diffMs = date - Date.now()
        const minutes = Math.max(0, Math.floor(diffMs / (60 * 1000) % 60))
        const hours = Math.floor(diffMs / (60 * 60 * 1000))
    
        let result = minutes + "m"
        if (hours > 0) {
            result = hours + "h " + result
        }
    
        if (result === "0m") {
            result = "now"
        }
    
        return result
    }

    useEffect(()=>{
        if(activeProxiesList.length > 0 && selectedRow.length === 0){
            setSelectedRow(activeProxiesList[0])
        }}
    )

    
    const rowClick = (row) => {
        setSelectedRow(row)
    }
    
    // const handleClick = (event, row) => {
        // console.log(row)
        // const diffMs = row?.until - Date.now()
        // const REFUND_MAX_HOURS = 3
        // const hoursUsed = 24 - Math.floor(diffMs / (60 * 60 * 1000))

        // if (hoursUsed < REFUND_MAX_HOURS) {
        //     setIsRefund(true)
        // }
        // console.log(diffMs)
        // setAnchorEl(event.currentTarget)
        // setSelectedRow(row)
    // }

    const handleClick = (row, event) => {
        setSelectedRow(row)
        setOpenDetails(true)
    }
    
    
    const handleClose = (key, row) => {
        // console.log(row)
        setSelectedRow(row)
    }

    return (
        <>
        {activeProxiesList.length > 0 ? (
            <div className={classes.main}>
                <div className={classes.header}>Active Proxies</div>
                <div className={classes.content}>
                    <div className={classes.tableDiv}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                            <Table stickyHeader sx={{borderColor: '#B6BABF'}}>
                                <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>IP</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Country</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>State</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>City</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Zip</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>ISP</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Blacklist</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Users</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Expire at</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {activeProxiesList.map((row, index) => (
                                    <TableRow hover
                                        onClick={() => rowClick(row)}
                                        selected={selectedRow?.id === row?.id}
                                    >
                                        <TableCell align="left" sx={{ borderBottom: "none", cursor:'pointer'}}>
                                            <Tooltip title={'Proxy ID: '+row?.id} placement="top">
                                                {row?.info?.ip}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px'}}>
                                            <ReactCountryFlag countryCode={row?.info?.loc?.cc} svg style={{width: '16px', height: '12px', marginRight:'5px'}}/>
                                            {row?.info?.loc?.cc}
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none",   padding: '10px' }}>{row?.info?.loc?.reg?.replace(/(.{10})..+/, "$1…")}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>{row?.info?.loc?.city?.replace(/(.{10})..+/, "$1…")}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>{row?.info?.loc?.zip||''}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>{row?.info?.loc?.isp?.replace(/(.{10})..+/, "$1…")}</TableCell>
                                        <TableCell align="center" sx={{ borderBottom: "none", padding: '10px' }}>{row?.info?.blacklists?.length||0}</TableCell>
                                        <TableCell align="center" sx={{ borderBottom: "none", padding: '10px' }}>{row?.currentInfo?.leases?.count||row?.info?.leases?.count}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none",  padding: '10px' }}>
                                            <div>
                                                {formatTimeLeft(row?.until)}
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(e)=>{handleClick(row, e)}}
                                                >
                                                    <InfoIcon color="primary"/>
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    </div>
                </div>
                <ProxyDetailsDialog open={openDetails} 
                                    selectedProxy={selectedRow} 
                                    setOpenDetails={setOpenDetails} 
                                    setReloadActive={props.setReloadActive}
                                    apiClient={props.apiClient}
                                    
                                    />
            </div>
            ):(
                <>
                    <EmptyTable name="Active Proxies" text="You don't have any active proxies."/>
                </>
            )
        }
        </>
    )
}

export default ActiveProxiesTable