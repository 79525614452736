import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useAuth } from "../api/authStore"
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import humanFormat from 'human-format'
import moment from 'moment'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const useStyles = makeStyles((theme) => ({
    mainDiv:{
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
    },
    divRow: {
        display: 'flex',
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
     },
     columnDiv: {
        flex: '50%',
     }
}))

export default function TicketDetailsDialog(props) {

    const ticket = props.ticket

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const [description, setDescription] = React.useState('')
    const [error, setError] = React.useState(null)

    const handleClose = () => {
        props.setOpen(false)
        setError(null)
        setDescription('')
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    const handleCloseTicket = async(status) => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).closeTicketdAdmin(ticket?.id, description, status)

            console.log(response)
            props.setOpen(false)
        } catch (e) {
            setError(JSON.stringify(e))
            console.log(e)
        }
    }

    return (
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ticket ID : "+ticket?.id}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Proxy ID : {ticket?.lease?.id}</Typography>
            <Typography>Reason : {ticket?.reason}</Typography>
            <Typography>Start Date : {moment(ticket?.lease?.start).format('MMM-D H:m, YYYY')}</Typography>
            <Typography>Exire Date : {moment(ticket?.lease?.until).format('MMM-D H:m, YYYY')}</Typography>
            <Typography>Traffic IN : {ticket?.lease?.stats?.outBytes>0?humanFormat.bytes(ticket?.lease?.stats?.outBytes, {decimals: 0}):0}</Typography>
            <Typography>Traffic OUT : {ticket?.lease?.stats?.inBytes>0?humanFormat.bytes(ticket?.lease?.stats?.outBytes, {decimals: 0}):0}</Typography>
            <TextField  size="small" 
                        sx={{padding: '0px 0px', paddingTop:'0', paddingBottom: '20px'}} 
                        inputProps={{sx:{padding:'5px 5px', width: '370px'}}} 
                        onChange={handleChangeDescription}
                                />
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography>Full Data</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{width:'120px', maxWidth: '120px'}}>
                    {JSON.stringify(ticket)}
                </AccordionDetails>
            </Accordion>
            {error !== null &&
                <div style={{color:'red'}}>{error}</div>
            }     
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleCloseTicket('refund')}} color='success' variant='outlined'>Refund</Button>
          <Button onClick={()=>{handleCloseTicket('reject')}} color='warning'>
            Reject
          </Button>
        </DialogActions>
      </Dialog>
  )
}