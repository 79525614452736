import * as React from 'react'
import { makeStyles } from '@mui/styles'
import LeftSideMenu from './LeftSideMenu'
import HistoryTable from './HistoryTable'

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        display: 'flex',
        height: '100%',
    },
}))

const History = (props) => {
    const classes = useStyles()
    const selectedRow = []
    

    return (
        <div className={classes.mainDiv}>
            <LeftSideMenu active={props.active} user={props.user} apiClient={props.apiClient}/>
            <HistoryTable apiClient={props.apiClient}/>
        </div>
    )
}

export default History