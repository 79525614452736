import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import { styled } from '@mui/material/styles'
// import TextField from '@mui/material/TextField'
// import Select from '@mui/material/Select'
// import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/joy/Input'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import TablePagination from '@mui/material/TablePagination'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { useAuth } from "./api/authStore"
// import Autocomplete from '@mui/material/Autocomplete'
import Autocomplete from '@mui/joy/Autocomplete'
import { countryNames } from './config/countryNames'
import ConfirmDialog from './ConfirmDialog'
import moment from 'moment'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import WifiIcon from '@mui/icons-material/Wifi'
import LteMobiledataIcon from '@mui/icons-material/LteMobiledata'
import InputLabel from '@mui/material/InputLabel'
import Option from '@mui/joy/Option'
import Select from '@mui/joy/Select'
import CircularProgress from '@mui/joy/CircularProgress'

const useStyles = makeStyles((theme) => ({
    main: {
        width: '80%',
        height: '100vh',
    },
    header: {
        marginTop: '32px',
        marginBottom: '22px',
        marginLeft: '10px',
        fontWeight: 600,
    },
    content: {
        height: '72px',
        marginLeft: '10px',
        // marginRight: '32px',
    },
    filterDiv: {

    },
    tableDiv: {
        marginTop: '16px',
    },
    ipInput: {
        // height: '56px',
        marginLeft: '16px',
        width: '120px'
    },
    countryInput: {
        width: '190px',
        // height: '56px',
    },
    selectState: {
        width: '145px',
        // height: '56px',
        marginLeft: '16px'
    },
    selectCity: {
        width: '150px',
        // height: '56px',
        marginLeft: '16px'
    },
    selectIsp: {
        width: '180px',
        // height: '56px',
        marginLeft: '16px'
    },
    selectZip: {
        width: '105px',
        // height: '56px',
        marginLeft: '16px'
    },
    selectType: {
        width: '100px',
        // height: '56px',
        marginLeft: '16px'
    },
    tableDataCell: {
        height: '20px',
        paddingTop: '15px',
    },
    blacklistDiv2: {
        color: '#212326',
        display:'inline-block',
    },
    emptyContent: {
        height: '72px',
        // marginLeft: '10px',
        marginRight: '18px',
        height: '400px',
        border: '1px solid',
        borderRadius: '16px',
        justifyContent: 'center',
        textAlign:'center',
        paddingTop: '200px',
        marginTop: '32px'
    },
}))

function TablePaginationActions(props) {
    const theme = useTheme()
    
    const { count, page, rowsPerPage, onPageChange } = props
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0)
    }
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1)
    }
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1)
    }
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
         <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
            <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        </IconButton>
      </Box>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}))

const Proxies = (props) => {
    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const classes = useStyles()

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [proxies, setProxiesList] = React.useState([])
    const [proxyCountries, setCountriesList] = React.useState([])
    const [proxyCountriesKeys, setCountriesKeysList] = React.useState([])
    const [afterBuy, setAfterBuy] = React.useState(false)
    // console.log(proxyCountriesKeys)
    

    const [country, setCountry] = React.useState('US')
    const [region, setRegion] = React.useState(null)
    const [selectedCity, setCity] = React.useState(null)
    const [selectedZip, setZip] = React.useState(null)
    const [selectedIsp, setIsp] = React.useState(null)
    const [selectedIp, setIp] = React.useState(null)
    const [selectedType, setType] = React.useState(null)

    const [selectedRow, setSelectedRow] = useState([])
    // const [selectedCountry, setSelectedCountry] = useState(countryNames['US'])
    // const [selectedCountryCode, setSelectedCountryCode] = useState('US')

    const [confirmOpen, setConfirmOpen] = React.useState(false)

    const [isCheckingSpeed, setIsCheckingSpeed] = React.useState([])
    // const [proxyPrice, setPrice] = React.useState(0)

    // const [speed, setSpeed] = useState(null)

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - proxies?.agents?.length||0) : 0

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        setIsCheckingSpeed([])
    }

    useEffect(()=>{
        const callAsync = async() => {
            try {
                const proxiesPesponse = await props.apiClient.setToken(getAccessTokenSilently()).loadProxies(page, rowsPerPage, country, selectedIp, region, selectedZip, selectedCity, selectedIsp, selectedType)
                // console.log(proxiesPesponse)
                setProxiesList(proxiesPesponse)

                setSelectedRow(proxiesPesponse?.agents[0])
                
                setAfterBuy(false)
                props.setReloadData(true)
                
                let proxiesSpeedCheckerArray = []
                proxiesPesponse?.agents?.map((row) => {
                    proxiesSpeedCheckerArray[row?.id] = false
                })

                setIsCheckingSpeed(proxiesSpeedCheckerArray)
            }catch(e){

            }
        }
        
        callAsync()   
      },[afterBuy, page, rowsPerPage, selectedIp, country, region, selectedCity, selectedZip, selectedIsp, selectedType])

    useEffect(()=>{
        const callAsync = async() => {
            try{
                const countriesPesponse = await props.apiClient.setToken(getAccessTokenSilently()).getCountries()
                // setCountriesList(countriesPesponse)

                const countriesKeys = countriesPesponse?.countries!==null?Object.keys(countriesPesponse?.countries):[]
            //   console.log(countriesKeys)
                setCountriesKeysList(countriesKeys)

                let countryListSorted = []

                countriesKeys.map((row)=>{
                    const countryObj = {
                        name: countryNames[row],
                        id: row,
                        count: countriesPesponse?.countries[row],
                    }

                    countryListSorted.push(countryObj)
                })

                countryListSorted.sort(function(a,b) {
                    return a.name.localeCompare(b.name)
                })

                setCountriesList(countryListSorted)
            }catch(e) {
                
            }
        }
        callAsync()   
    },[])

    
    const rowClick = (row) => {
        // console.log(row.id)
        setSelectedRow(row)
        // setSpeed((selectedRow?.speed?.speed / 1000 / 1000).toFixed(2))
    }

    const selectCountry = (cc) => {
        if(cc !== null){
            setCountry(cc.id)
            // console.log(cc.id)
        }
        
        // setSelectedCountry(countryNames[cc])
        // setSelectedCountryCode(cc)
    }

    const selectIp = (ip) => {
        if(ip == "") {
            setIp(null)
        } else {
            setIp(ip)
        }
        
    }

    const selectState = (reg) => {
        if(reg == ""){
            setRegion(null)
        } else {
            setRegion(reg)
        }
        

    }

    const selectZip = (zip) => {
        if(zip == ""){
            setZip(null)
        } else {
            setZip(zip)
        }

    }

    const selectIsp = (isp) => {
        if (isp == ""){
            setIsp(null)
        } else {
            setIsp(isp)
        }
        
    }

    const selectCity = (city) => {
        if(city == ""){
            setCity(city)
        } else {
            setCity(city)
        }
        
        // console.log(city)
    }

    const selectType = (event, newValue) => {
        if(newValue == ""){
            setType(null)
        } else {
            setType(newValue)
        }
    }

    const handleBuyButtomClick = (row) => {
        // console.log('buttonclick')
        setSelectedRow(row)
        setConfirmOpen(true)
    }

    const checkSpeed = (id) => {
        const callAsync = async() => {
            try{
                setIsCheckingSpeed(datas=>({
                    ...datas,
                    [id]: true
                 }))

                 
                const response = await props.apiClient.setToken(getAccessTokenSilently()).checkProxySpeed(id)
                const speed = ((response?.speed / 1000 / 1000).toFixed(2))
                document.getElementById(id).innerHTML= speed+' Mbps'
                
                setIsCheckingSpeed(datas=>({
                    ...datas,
                    [id]: false
                 }))

            }catch(e) {
                // setIsCheckingSpeed([])
            }
        }
        callAsync()   
    }

    
    return (
    <>
        
            <>
            <div className={classes.main}>
                <div className={classes.header}>Proxies Store</div>
                <div className={classes.content}>
                    <div className={classes.filterDiv}>
                        <FormControl>
                        <Autocomplete 
                                    className={classes.countryInput}
                                    defaultValue={{id: 'US', name:"United States", count: 8}} 
                                    placeholder="Country"
                                    onChange={(event, value) => selectCountry(value)}
                                    options={proxyCountries||[]}
                                    getOptionLabel={(option) => option.name}
                                    disableportal={true.toString()}
                                   
                                />
                        </FormControl>
                        <FormControl>
                            <Input
                                        id="outlined-size-small"
                                        placeholder="IP Address"
                                        className={classes.ipInput}
                                        inputprops={{
                                            style: {
                                            borderRadius: "8px",
                                            }
                                        }}
                                        // onMouseLeave={v=>selectIp(v.target.value)}
                                        onBlur={v=>selectIp(v.target.value)}
                                    />
                        </FormControl>
                        <FormControl>
                            <Autocomplete
                                    className={classes.selectState}
                                    disableportal={true.toString()}
                                    id="combo-box-state"
                                    options={proxies?.segments?.regions?.sort(function(a,b) {
                                        return a.localeCompare(b)
                                    })||[]}
                                    onChange={(event, value) => selectState(value)}
                                    placeholder="State"
                                    />
                        </FormControl>
                        <FormControl>
                            <Autocomplete
                                    className={classes.selectCity}
                                    disableportal={true.toString()}
                                    id="combo-box-city"
                                    options={proxies?.segments?.cities?.sort(function(a,b) {
                                        return a.localeCompare(b)
                                    })||[]}
                                    onChange={(event, value) => selectCity(value)}
                                    placeholder="City"
                                    // renderInput={(params) =>
                                    //     <TextField {...params}
                                    //         label="City"
                                    //         InputProps={{
                                    //             ...params.InputProps,
                                    //             disableUnderline: true,
                                    //             readOnly: Boolean(props.value),
                                    //         }}
                                    //         />}
                                    />
                        </FormControl>
                        <FormControl>
                            <Autocomplete
                                className={classes.selectZip}
                                disableportal={true.toString()}
                                id="combo-box-zip"
                                options={proxies?.segments?.zips?.sort(function(a,b) {
                                    return a.localeCompare(b)
                                })||[]}
                                onChange={(event, value) => selectZip(value)}
                                placeholder="Zip"
                                // renderInput={(params) =>
                                //     <TextField {...params}
                                //         label="Zip"
                                //         InputProps={{
                                //             ...params.InputProps,
                                //             disableUnderline: true,
                                //             readOnly: Boolean(props.value),
                                //         }}
                                //         />}
                                />
                        </FormControl>
                        <FormControl>
                            <Autocomplete
                                className={classes.selectIsp}
                                disableportal={true.toString()}
                                id="combo-box-isp"
                                options={proxies?.segments?.isps?.sort(function(a,b) {
                                    return a.localeCompare(b)
                                })||[]}
                                onChange={(event, value) => selectIsp(value)}
                                placeholder="ISP"
                                // renderInput={(params) =>
                                //     <TextField {...params}
                                //         label="ISP"
                                //         InputProps={{
                                //             ...params.InputProps,
                                //             disableUnderline: true,
                                //             readOnly: Boolean(props.value),
                                //         }}
                                //         />}
                                />
                        </FormControl>
                        <FormControl>
                                <Select className={classes.selectType}
                                        defaultValue={'wifi'}
                                        placeholder="Type" 
                                        label="Type" 
                                        labelId="type-select-label"
                                        onChange={selectType}>
                                        <Option value="">All</Option>
                                        <Option value='wifi'>Wifi</Option>
                                        <Option value='cell'>Mobile</Option>
                                </Select>
                        </FormControl>
                    </div>
                    {proxies?.agents?.length>0 ? (
                    <div className={classes.tableDiv}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                            <Table stickyHeader sx={{borderColor: '#B6BABF'}}>
                                <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>IP</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>State</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>City</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Zip</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>ISP(Type)</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Users</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Blacklists</TableCell>
                                    {/* <TableCell align="left" sx={{ borderBottom: "none" }}>Speed</TableCell> */}
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>Price</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {proxies?.agents?.map((row) => (
                                    <TableRow hover
                                        onClick={() => rowClick(row)}
                                        selected={selectedRow?.id === row.id}
                                        
                                    >
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px'}}>{row?.ip.replace(/\_/g, '*')}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>{row?.loc?.reg?.replace(/(.{10})..+/, "$1…")}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>{row?.loc?.city?.replace(/(.{10})..+/, "$1…")}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>{row?.loc?.zip}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>
                                            {row?.loc?.isp?.replace(/(.{12})..+/, "$1…")}
                                            {row?.conn === 'wifi' ? (
                                               <WifiIcon color="primary" sx={{ fontSize: 16, paddingLeft: '8px' }}/>
                                            ):(
                                               <LteMobiledataIcon color="primary" sx={{ fontSize: 20, paddingLeft: '8px' }}/>
                                            )}
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>{row?.leases?.count>0?row?.leases?.count+'/4':row?.leases?.count}</TableCell>
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>
                                            {row?.blacklists?.listIDs?.length > 0 ? (
                                                    <>
                                                    <HtmlTooltip
                                                            title={
                                                            <React.Fragment>
                                                                <div className={classes.blacklistDiv2}>
                                                                    {row?.blacklists?.listIDs.map((list) => (
                                                                      <Typography color="inherit">{list}</Typography>
                                                                    ))} 
                                                                </div>
                                                            </React.Fragment>
                                                            }
                                                        >
                                                        <Link href="#">({row?.blacklists?.listIDs?.length})</Link>
                                                    </HtmlTooltip>
                                                    </>
                                                 ):(
                                                    <>0</>
                                            )}
                                        </TableCell>
                                        {/* <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>
                                            {isCheckingSpeed[row?.id] === true ? (
                                                <>
                                                    <CircularProgress size="sm"  thickness={2}/>
                                                    <Link href="#" id={row?.id} />
                                                </>
                                            ):(
                                                <Link href="#" id={row?.id} onClick={()=>{checkSpeed(row?.id)}}>{row?.speed?.speed ? (row?.speed?.speed / 1000 / 1000).toFixed(2):'0.00'} Mbps</Link>
                                            )}
                                            
                                        </TableCell> */}
                                        <TableCell align="left" sx={{ borderBottom: "none", padding: '10px' }}>
                                            <Button variant='outlined'
                                                    onClick={() => {handleBuyButtomClick(row)}}
                                            >
                                                Buy ${(row?.priceShrC/100).toFixed(2)}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div>
                            <TablePagination
                                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                            colSpan={3}
                                            count={proxies?.total||0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            slotProps={{
                                                select: {
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                                },
                                            }}
                                            ActionsComponent={TablePaginationActions}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                            </div>
                        </Paper>
                    </div>
                     ):(
                        <div className={classes.emptyContent}><span>No proxies</span></div>
                    )
                    }
                </div>
                </div>
                <ConfirmDialog open={confirmOpen} setConfirmOpen={setConfirmOpen} selectedProxy={selectedRow} apiClient={props.apiClient} setAfterBuy={setAfterBuy}/>
                </>
           
    </>
    )
}

export default Proxies