import * as React from 'react'
import { makeStyles } from '@mui/styles'
import LeftSideMenu from './LeftSideMenu'
import TicketsTable from './TicketsTable'

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        display: 'flex',
        height: '100%',
    },
}))

const Tickets = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.mainDiv}>
            <LeftSideMenu active={props.active} user={props.user} apiClient={props.apiClient}/>
            <TicketsTable apiClient={props.apiClient}/>
        </div>
    )
}

export default Tickets